import React, { useEffect } from 'react';
import { RouteProps, Switch } from 'react-router';
import { Redirect, Route as RouterRoute } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLingui } from '@lingui/react';
import NewsManagementPage from './news/NewsManagementPage';
import { useWindowSize } from '~common/utils/layout.utils';

import { isDesktop } from '~common/utils/styled.utils';

import ProgressBar from '~sections/ProgressBar';
import ErrorBoundary from '~utils/ErrorBoundary';
import { app } from '~common/app.model';
import { useActions } from '~common/utils/hooks.utils';

// Code split routes
const Content = React.lazy(() => import('./content'));
const FilesAdd = React.lazy(() => import('./content/files/add'));
const FilesAddMultiple = React.lazy(
  () => import('./content/files/addMultiple')
);
const FileEdit = React.lazy(() => import('./content/files/edit'));
const FilesEditMultiple = React.lazy(
  () => import('./content/files/editMultiple')
);
const MassImportEdit = React.lazy(
  () => import('./content/products/editMassImport')
);
const SubtitleEditor = React.lazy(() => import('./content/files/subtitles'));
const Home = React.lazy(() => import('./home'));
const Orders = React.lazy(() => import('./orders'));
const OrderAdd = React.lazy(() => import('./orders/add'));
const OrderPage = React.lazy(() => import('./orders/OrderPage'));
const Products = React.lazy(() => import('./content/products'));
const Settings = React.lazy(() => import('./settings'));
const LayoutSettings = React.lazy(() => import('./settings/layout'));
const PreviewWrapper = React.lazy(
  () => import('./settings/layout/PreviewWrapper')
);
const CategoryTreePage = React.lazy(
  () => import('./content/categoryTree/CategoryTreePage')
);
const Comments = React.lazy(() => import('./comments'));
const CommentsAdd = React.lazy(() => import('./comments/add'));
const CommentsEdit = React.lazy(() => import('./comments/edit'));
const CommentsOverview = React.lazy(() => import('./comments/overview'));
const ReportsDashboard = React.lazy(() => import('./reports/dashboard'));
const ReportsCount = React.lazy(() => import('./reports/count'));
const Registration = React.lazy(
  () => import('./registration/RegistrationPage')
);
const SetPasswordPage = React.lazy(
  () => import('./registration/SetPasswordPage')
);
const UserManagementPage = React.lazy(
  () => import('./users/UserManagementPage')
);
const UserGroupsPage = React.lazy(() => import('./users/UserGroupsPage'));
const UploadFormsPage = React.lazy(
  () => import('./content/uploadForms/UploadFormsPage')
);
const ConsentTermsPage = React.lazy(
  () => import('./content/consentRequests/consentTerms/ConsentTermsPage')
);

const TaggedPersonListPage = React.lazy(
  () => import('./content/consentRequests/personTags/TaggedPersonListPage')
);

const EditUploadFormPage = React.lazy(
  () => import('./content/uploadForms/EditUploadFormPage')
);
const UploadFormPage = React.lazy(
  () => import('./content/uploadForms/UploadFormPage')
);
const SynkkaListingPage = React.lazy(
  () => import('./content/synkka/SynkkaListingPage')
);

function Router() {
  const share = useSelector(state => state.app.share);
  const pathname = useSelector(state => state.router.location.pathname);
  const userRights = useSelector(state => state.app.userRights);
  const leftDock = useSelector(state => state.app.leftDock);
  const rightDock = useSelector(state => state.app.rightDock);
  const settings = useSelector(state => state.app.settings);
  const { toggleLeft, toggleRight } = useActions(app.actions);

  const windowSize = useWindowSize();
  const desktop = isDesktop(windowSize.innerWidth);
  const { logout } = useActions(app.actions);
  useEffect(() => {
    if (pathname === '/logout') logout();
  }, [pathname]);

  useEffect(() => {
    // Scroll window to top.
    // Leave 1px to keep mobile phone address bar minimized.
    window.scrollTo(0, 1);
    // Scroll main content to top
    setTimeout(() => {
      const el = document.getElementById('mainContent');
      if (el) {
        // NOTE: old Edge does not support scrollTo. Remove this fix later.
        try {
          el.scrollTo(0, 0);
        } catch (err) {
          el.scrollTop = 0;
        }
      }
    });
  }, [pathname]);

  useEffect(() => {
    // Show/hide docks based on route
    if (
      (new RegExp('^/folders/[^/]*$').test(pathname) && !share) ||
      new RegExp('^/archive/[^/]*$').test(pathname) ||
      new RegExp('^/shopping/[^/]*$').test(pathname) ||
      (new RegExp('^/workspaces/[^/]*$').test(pathname) && !share) ||
      pathname === '/search'
    ) {
      toggleLeft({
        open: leftDock.open,
        visible: desktop,
        content: '/contentTabs',
      });
      toggleRight({
        open: rightDock.open,
        visible: desktop && userRights?.CARTS_MANAGE,
        content: '/workspaces',
      });
    } else if (
      pathname.startsWith('/products') ||
      pathname.startsWith('/templates')
    ) {
      toggleLeft({
        open: leftDock.open,
        visible: windowSize.innerWidth >= 1000,
        content: '/productPages',
      });
      toggleRight({
        open: rightDock.open,
        visible: windowSize.innerWidth >= 1000,
        content: '/productElement',
      });
    } else if (pathname.includes('/comments') && pathname.includes('/edit')) {
      toggleLeft({
        open: leftDock.open,
        visible: windowSize.innerWidth >= 1000,
        content: '/commentPages',
      });
      toggleRight({
        open: rightDock.open,
        visible: windowSize.innerWidth >= 1000,
        content: '/commentList',
      });
    } else {
      toggleLeft({ open: leftDock.open, visible: false, content: null });
      toggleRight({ open: rightDock.open, visible: false, content: null });
    }
  }, [pathname, windowSize, userRights]);

  const getRootRedirect = () => {
    if (share && share.type === 'w') {
      return `/workspaces/${share.id}`;
    }
    if (share && share.type === 'f') {
      return `/folders/${share.id}`;
    }
    if (share && share.type === 'c') {
      return `/consent_requests/${share.id}`;
    }
    if (share && share.type === 'o') {
      return `/comments/${share.id}/edit`;
    }
    if (share && share.type === 'i') {
      return `/invite/${share.id}`;
    }
    if (userRights?.SHOW_FRONTPAGE) {
      return '/home';
    }
    return `/folders/${settings?.contentFolderId}`;
  };

  const getGotoRedirect = (name: string) => {
    if (name === 'content') return `/folders/${settings?.contentFolderId}`;
    if (name === 'userContent')
      return `/folders/${settings?.userContentFolderId}`;
    return '/home';
  };

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<ProgressBar />}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Redirect to={getRootRedirect()} />}
          />
          <Route
            exact
            path="/home"
            render={navProps =>
              userRights && userRights.SHOW_FRONTPAGE ? (
                <Home {...navProps.match.params} />
              ) : (
                <Redirect to="/goto/content" />
              )
            }
          />
          <Route
            exact
            path="/goto/:name"
            render={navProps => (
              <Redirect to={getGotoRedirect(navProps.match.params.name)} />
            )}
          />
          <Route
            exact
            path="/folders/:id"
            render={() => <Content mode={share ? 'folderShared' : 'folder'} />}
          />
          <Route
            exact
            path="/folders/:id/browse"
            render={navProps => (
              <Content
                {...navProps.match.params}
                browseMode
                mode={share ? 'folderShared' : 'folder'}
              />
            )}
          />
          <Route
            exact
            path="/workspaces/:id"
            render={navProps => (
              <Content
                {...navProps.match.params}
                mode={share ? 'workspaceShared' : 'workspace'}
              />
            )}
          />
          <Route
            exact
            path="/workspaces/:id/browse"
            render={navProps => (
              <Content
                {...navProps.match.params}
                mode={share ? 'workspaceShared' : 'workspace'}
                browseMode
              />
            )}
          />
          <Route
            exact
            path="/consent_requests/:id"
            render={() => <Content mode="consentRequest" />}
          />
          <Route
            exact
            path="/consent_requests/:id/browse"
            render={() => <Content mode="consentRequest" browseMode />}
          />
          <Route
            exact
            path="/archive/:id"
            render={() => <Content mode="archive" />}
          />
          <Route
            exact
            path="/archive/:id/browse"
            render={() => <Content mode="archive" browseMode />}
          />
          <Route
            exact
            path="/shopping/:id"
            render={() => <Content mode="shopping" />}
          />
          <Route
            exact
            path="/shopping/:id/browse"
            render={() => <Content mode="shopping" browseMode />}
          />
          <Route
            exact
            preserveOnLocaleChange
            path="/folders/:folderId/add"
            render={() => <FilesAdd />}
          />
          <Route
            exact
            preserveOnLocaleChange
            path="/folders/:folderId/addMultiple"
            render={() => <FilesAddMultiple />}
          />
          <Route
            exact
            preserveOnLocaleChange
            path="/files/:fileId/edit"
            render={() => <FileEdit />}
          />
          <Route
            exact
            preserveOnLocaleChange
            path="/files/editMultiple"
            render={() => <FilesEditMultiple />}
          />
          <Route
            exact
            path="/files/:fileId/subtitles"
            render={navProps => <SubtitleEditor {...navProps.match.params} />}
          />
          <Route
            exact
            path="/files/synkka"
            render={() => <SynkkaListingPage />}
          />
          <Route
            exact
            path="/news/manage"
            render={() => <NewsManagementPage />}
          />
          <Route
            exact
            path="/search"
            render={navProps => (
              <Content {...navProps.match.params} mode="search" />
            )}
          />
          <Route
            exact
            path="/search/browse"
            render={navProps => (
              <Content {...navProps.match.params} mode="search" browseMode />
            )}
          />
          <Route
            exact
            path="/orders"
            render={navProps => <Orders {...navProps.match.params} />}
          />
          <Route
            exact
            path="/orders/browse"
            render={navProps => (
              <Orders {...navProps.match.params} browseMode />
            )}
          />
          <Route
            exact
            path="/orders/add"
            render={navProps => <OrderAdd {...navProps.match.params} />}
          />
          <Route
            exact
            path="/orders/:id"
            render={navProps => <OrderPage {...navProps.match.params} />}
          />
          <Route
            exact
            path="/products/:id"
            render={navProps => <Products {...navProps.match.params} />}
          />
          <Route
            exact
            path="/templates/:id/personalize"
            render={navProps => (
              <Products {...navProps.match.params} mode="template" />
            )}
          />
          <Route
            exact
            path="/settings"
            render={navProps => <Settings {...navProps.match.params} />}
          />
          <Route
            exact
            path="/settings/layout"
            render={navProps => <LayoutSettings {...navProps.match.params} />}
          />
          <Route
            exact
            path="/settings/layout/preview"
            render={navProps => (
              <PreviewWrapper>
                <Home {...navProps.match.params} preview />
              </PreviewWrapper>
            )}
          />
          <Route
            exact
            path="/categorytree"
            render={() => <CategoryTreePage />}
          />
          <Route
            exact
            path="/comments"
            render={navProps => <Comments {...navProps.match.params} />}
          />
          <Route
            exact
            path="/comments/add"
            render={navProps => <CommentsAdd {...navProps.match.params} />}
          />
          <Route
            exact
            path="/comments/:id/edit/:page?"
            render={navProps => <CommentsEdit {...navProps.match.params} />}
          />
          <Route
            exact
            path="/comments/:id"
            render={navProps => <CommentsOverview {...navProps.match.params} />}
          />
          <Route
            exact
            path="/reports/:id"
            render={navProps => (
              <ReportsCount
                reportId={navProps.match.params.id}
                {...navProps.match.params}
              />
            )}
          />
          <Route
            exact
            path="/reports"
            render={navProps => <ReportsDashboard {...navProps.match.params} />}
          />
          <Route
            exact
            path="/registration"
            render={() =>
              userRights ? (
                <Redirect to={getRootRedirect()} />
              ) : (
                <Registration />
              )
            }
          />
          <Route
            exact
            path="/set_password"
            render={() =>
              userRights ? (
                <Redirect to={getRootRedirect()} />
              ) : (
                <SetPasswordPage />
              )
            }
          />
          <Route exact path="/users" render={() => <UserManagementPage />} />
          <Route exact path="/usergroups" render={() => <UserGroupsPage />} />

          <Route
            exact
            path="/invite/:inviteId"
            render={() => <UploadFormPage />}
          />
          <Route exact path="/uploadforms" render={() => <UploadFormsPage />} />
          <Route
            exact
            path="/uploadforms/:id"
            render={() => <EditUploadFormPage />}
          />
          <Route
            exact
            preserveOnLocaleChange
            path="/massimport/:id/edit"
            render={() => <MassImportEdit />}
          />
          <Route
            exact
            path="/consents/terms"
            render={() => <ConsentTermsPage />}
          />
          <Route
            exact
            path="/consents/persons"
            render={() => <TaggedPersonListPage />}
          />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  );
}

const Route = <Path extends string = string>({
  preserveOnLocaleChange,
  ...props
}: { preserveOnLocaleChange?: boolean } & RouteProps<Path>) => {
  const { i18n } = useLingui();
  return (
    <RouterRoute
      key={preserveOnLocaleChange ? undefined : i18n.locale}
      {...props}
    />
  );
};

export default Router;
