import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { defineMessage, t } from '@lingui/macro';

import { Theme } from '@mui/material';
import { useOpenWorkspace } from '../workspaces/hooks';
import { TAGGABLE_SUGGESTION_IDS } from '../search/constants';
import { LayoutConfig } from '../files/common/add.types';

import {
  SearchTag,
  File,
  MetaField,
  DateRangeField,
  ArchiveField,
} from '~common/content.types';
import {
  getOriginalUrl,
  updateContentCriteria,
  useCriteria,
  useCurrentFolderId,
} from '~common/content.utils';
import { useGetContentsQuery, useGetFolderQuery } from '~common/content.api';
import { nextFunc, prevFunc } from '~common/paging/utils';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { isDesktop } from '~common/utils/styled.utils';
import { useWindowSize } from '~common/utils/layout.utils';
import { ContentModeType, Criteria, Results } from '~common/common.types';
import { getStringByLang } from '~common/utils/i18n';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';
import { ConsentStatus } from '~common/content.constants';

export const isVideoFile = (file?: File) =>
  file?.propertiesById?.['nibo:mime-type']?.includes('video');

export const isCroppableFile = (file?: File) => {
  if (!file?.propertiesById?.['nibo:mime-type']) return false;
  const mimeType = file.propertiesById['nibo:mime-type'].trim();
  return (
    mimeType.indexOf('image/') === 0 ||
    mimeType === 'application/postscript' ||
    mimeType === 'application/eps'
  );
};

/** Whether the item can be removed from a workspace or not */
export const itemCanBeRemovedFromWorkspace = (item?: File) =>
  !!item && (item.node.inCart || item.node.inShoppingCart);

export enum SearchSuggestionType {
  TAG,
  LITERAL,
}

export const getSearchSuggestionType = (id: string) => {
  if (TAGGABLE_SUGGESTION_IDS.some(tagId => id.includes(tagId))) {
    return SearchSuggestionType.TAG;
  }
  return SearchSuggestionType.LITERAL;
};

export const parseTags = (tags: string) =>
  decodeURIComponent(tags)
    .split('|||')
    .filter(x => !!x)
    .map(tag => {
      const [id, value] = tag.split('===');
      return {
        id,
        value: value,
      } as SearchTag;
    });

export const encodeTags = (tags: SearchTag[]) =>
  tags.map(t => `${t.id}===${t.value}`).join('|||');

/**
 * Removes duplicates from an array. Two values are deemed as the same
 * if `idFunc`returns the same value for both of them
 *
 * @example
 *
 * const arr = [{ id: 1, val: 'foo '}, { id: 1, val: 'bar' }];
 *
 * const uniq = uniqueBy(arr, entry => entry.id);
 * // -> Evaluates to [{ id: 1, val: 'bar' }]
 */
export function uniqueBy<T, K extends string | number | symbol>(
  arr: T[],
  idFunc: (val: T) => K
) {
  return Object.values(
    arr.reduce((obj, cur) => {
      const id = idFunc(cur);
      return {
        ...obj,
        [id]: {
          ...obj[id],
          ...cur,
        },
      };
    }, {} as Record<K, T>)
  ) as T[];
}

type ContentMode = {
  mode: ContentModeType;
  browseMode: boolean;
};

export const ContentModeContext = createContext<ContentMode>({} as ContentMode);

/** Returns current `BrowseContentPage`'s `mode` and `browseMode`.
 * Must be used on a `BrowseContentPage`'s child component! */
export function useContentMode() {
  return useContext(ContentModeContext);
}

/** Returns wether the search should be targeted to the archive
 * or use the default target */
export function useSearchTarget() {
  const contentMode = useSelector(state => state.content.mode);
  const { target } = useCriteria();

  return contentMode === 'archive' || target === 'archive'
    ? 'archive'
    : undefined;
}

/** Returns the current folder when browsing content */
export function useCurrentFolder() {
  const { mode } = useContentMode();
  const password = useSelector(state => state.commonContent.password);
  const folderId = useCurrentFolderId();
  const { data: folder } = useGetFolderQuery(
    folderId && mode !== 'search' ? { id: folderId, password } : skipToken
  );

  return folder;
}

export function useGetContentsParams() {
  const id = useCurrentFolderId();
  const criteria = useCriteria();
  const language = useSelector(state => state.app.settings?.language);

  // We don't want the contentsQuery to fire unless relevant parts of
  // `criteria` change, so we strip `selectedId` and `selectedIndex`
  const { selectedId, selectedIndex, ...queryCriteria } = criteria;
  const memoedCriteria = useMemo(() => queryCriteria, [criteria]);

  // Shared workspaces might require a password
  const password = useSelector(state => state.commonContent.password);

  return {
    id,
    criteria: memoedCriteria,
    password,
    language,
    useFastEndpoint: true,
    report: true,
  };
}

export function usePagedResults() {
  const filesById = useSelector(state => state.commonContent.filesById);
  const foldersById = useSelector(state => state.commonContent.foldersById);
  const setFolderCriteria = useActions(app.actions.setUserFolderSort);
  const setSearchCriteria = useActions(app.actions.setUserSearchSort);
  const id = useCurrentFolderId();
  const criteria = useCriteria();

  const redirectTo = useRedirectTo();
  const { mode, browseMode } = useContentMode();

  const { openWorkspace } = useOpenWorkspace();
  const windowSize = useWindowSize();
  const desktop = isDesktop(windowSize.innerWidth);

  const target = useSearchTarget();

  const getContentsParams = useGetContentsParams();
  const {
    data: contents,
    isFetching,
    error,
  } = useGetContentsQuery(getContentsParams);

  const results: Results = {
    totalCount: contents ? Number(contents.totalCount) : 0,
    items: !isFetching && contents ? contents.items || [] : [],
    status: isFetching ? 'loading' : null,
    errorCode: (error && 'status' in error && error.status) || undefined,
    key: `${id}-${criteria.page}-${criteria.sortBy}`,
  };

  const onOpenFolder = (item: File) => {
    if (mode === 'search' && (item.isCart || item.node.inCart)) {
      // Open the selected folder in workspace
      const wip = item.isCart ? item.node.id : item.node.parentId;
      if (desktop) {
        openWorkspace(wip, 'center');
      } else {
        redirectTo(`/workspaces/${wip}`);
      }
    } else {
      // Navigate to the selected folder
      // TODO: Allow selecting the folder with arrow keys and enter
      updateContentCriteria(item.node.id, {
        selectedIndex: -1,
        selectedId: null,
        target,
      });
    }
  };

  const onSelectItem = (
    index: number,
    eventTargetIndex: number,
    keyCode?: number | null
  ) => {
    const item =
      index !== -1 ? results.items[index] : results.items[eventTargetIndex];
    const isFolder = item && (item.isFolder || item.isCart);

    // Fetch concrete item, as item may be a link
    // TODO: duplicate logic with SelectedFile.tsx
    const concreteId = item.concrete?.id ?? null;
    const concrete = concreteId
      ? isFolder
        ? item && foldersById[concreteId] && foldersById[concreteId]?.folder
        : item && filesById[concreteId] && filesById[concreteId]?.file
      : null;

    if (item && (keyCode === 13 || (isFolder && index === eventTargetIndex))) {
      if (isFolder) {
        onOpenFolder(item);
      } else if (concrete?.isMasterProduct || concrete?.isUserProduct) {
        // Personalize product
        redirectTo('/products/1258');
      } else {
        // Open file in another tab
        window.open(getOriginalUrl(item));
      }
    } else {
      const enterBrowseMode = !browseMode && !desktop;
      // Select item
      updateContentCriteria(
        id,
        {
          ...criteria,
          selectedIndex: index,
          selectedId: index !== -1 && item ? item.node.id : null,
        },
        null,
        enterBrowseMode ? 'browse' : null
      );
    }
  };

  const onUpdatePaging = (newPaging: Partial<Criteria>) => {
    if (window.location.pathname === '/ui/search') {
      setSearchCriteria(newPaging.sortBy ?? '');
    } else {
      setFolderCriteria(newPaging.sortBy ?? '');
    }
    updateContentCriteria(id, {
      ...criteria,
      selectedIndex: -1,
      selectedId: null,
      ...newPaging,
    });
  };

  const onSelectPrevItem = prevFunc(
    criteria.selectedIndex,
    criteria,
    onUpdatePaging,
    onSelectItem
  );

  const onSelectNextItem = nextFunc(
    criteria.selectedIndex,
    criteria,
    onUpdatePaging,
    onSelectItem,
    results
  );

  return {
    results,
    contents,
    isFetching,
    error,
    onSelectItem,
    onOpenFolder,
    onSelectPrevItem,
    onSelectNextItem,
    onUpdatePaging,
  };
}

export const DESCRIPTION_FIELD_ID = 'nibo:description';
export const INSTRUCTION_FIELD_ID = 'nibo:info';

/** Adapts the description field parameters to the folders layout config
 * Returns null if the description shouldn't be asked at the folder */
export const getDescriptionField = (config: LayoutConfig): MetaField | null =>
  config.excludeDescription
    ? null
    : {
        id: DESCRIPTION_FIELD_ID,
        namesByLang: getStringByLang(defineMessage({ id: 'Description' })),
        type: 'unspecified',
        valueType: 'text',
        placement: -1,
        isLocalized: false,
        isMandatory: Boolean(config.mandatoryDescription),
        isReadOnly: false,
        isVisible: false,
        isTranslation: false,
        isCopy: false,
        position: {
          search: 0,
          searchField: 0,
        },
        settings: {},
        autocomplete: false,
      };

const getInstructionField = (): MetaField | null => {
  return {
    id: INSTRUCTION_FIELD_ID,
    namesByLang: getStringByLang(defineMessage({ id: 'Instruction' })),
    type: 'unspecified',
    valueType: 'wysiwyg',
    placement: -1,
    isLocalized: false,
    isMandatory: false,
    isReadOnly: false,
    isVisible: false,
    isTranslation: false,
    isCopy: false,
    position: {
      search: 0,
      searchField: 0,
    },
    settings: {},
    autocomplete: false,
  };
};

export const PROPERTY_VALIDITY_PERIOD = 'nibo:validity-period';
export const PROPERTY_VALIDITY_PERIOD_START = 'nibo:validity-period-start';
export const PROPERTY_VALIDITY_PERIOD_END = 'nibo:validity-period-end';

export const PROPERTY_MARKED_FOR_ARCHIVING = 'nibo:marked-for-archiving';
export const PROPERTY_ARCHIVE_SCHEDULED = 'nibo:archive-scheduled';
export const PROPERTY_ARCHIVE_SCHEDULE_TIME = 'nibo:archive-schedule-time';
export const PROPERTY_ARCHIVE_ORIGINAL_PATH = 'nibo:archive-original-path';
export const PROPERTY_ARCHIVE_DESTINATION_PATH =
  'nibo:archive-destination-path';

export const isBasicFieldWithoutLanguageSupport = (fieldId: string) => {
  return (
    fieldId.startsWith(PROPERTY_VALIDITY_PERIOD) ||
    fieldId === PROPERTY_MARKED_FOR_ARCHIVING ||
    fieldId.startsWith('nibo:archive')
  );
};

export const getValidityPeriodFields = (): DateRangeField => {
  return {
    id: PROPERTY_VALIDITY_PERIOD,
    fromDateFieldId: PROPERTY_VALIDITY_PERIOD_START,
    toDateFieldId: PROPERTY_VALIDITY_PERIOD_END,
    namesByLang: getStringByLang(defineMessage({ id: 'Validity period' })),
    type: 'unspecified',
    valueType: 'date-range',
    placement: -1,
    isLocalized: false,
    isMandatory: false,
    isReadOnly: false,
    isVisible: false,
    isTranslation: false,
    isCopy: false,
    position: {
      search: 0,
      searchField: 0,
    },
    settings: {},
    autocomplete: false,
  };
};

export const getArchiveFields = (): ArchiveField => {
  return {
    id: PROPERTY_MARKED_FOR_ARCHIVING,
    scheduledFieldId: PROPERTY_ARCHIVE_SCHEDULED,
    scheduleTimeFieldId: PROPERTY_ARCHIVE_SCHEDULE_TIME,
    originalPathFieldId: PROPERTY_ARCHIVE_ORIGINAL_PATH,
    destinationPathFieldId: PROPERTY_ARCHIVE_DESTINATION_PATH,

    namesByLang: getStringByLang(defineMessage({ id: 'Archive' })),
    type: 'unspecified',
    valueType: 'archive',
    placement: -1,
    isLocalized: false,
    isMandatory: false,
    isReadOnly: false,
    isVisible: false,
    isTranslation: false,
    isCopy: false,
    position: {
      search: 0,
      searchField: 0,
    },
    settings: {},
    autocomplete: false,
  };
};

/** Returns metafields with optional description and instruction fields */
export const getMetaFieldWithStaticFields = (
  layoutConfig: LayoutConfig,
  metaFields: MetaField[],
  includeDescription: boolean,
  includeInstruction: boolean,
  includeValidityPeriod?: boolean,
  includeArchive?: boolean
) => {
  const descriptionField = includeDescription
    ? getDescriptionField(layoutConfig)
    : null;
  const instructionField = includeInstruction ? getInstructionField() : null;
  const validityPeriodFields = includeValidityPeriod
    ? getValidityPeriodFields()
    : null;
  const archiveFields = includeArchive ? getArchiveFields() : null;

  return [
    descriptionField,
    instructionField,
    validityPeriodFields,
    archiveFields,
    ...metaFields,
  ].filter((field): field is MetaField => !!field);
};

/** Converts the customUploadLayout property to specific upload layout properties */
export const getUploadLayoutConfig = (folder?: File): LayoutConfig => {
  if (!folder) return {};
  const helper = (configValues: number[]) =>
    configValues.includes(Number(folder.customUploadLayout)) ||
    (!Number(folder.customUploadLayout) &&
      configValues.includes(Number(folder.inheritedCustomUploadLayout)));
  return {
    excludeDescription: helper([2, 5]),
    mandatoryDescription: helper([4, 7]),
    excelActions: helper([5, 6, 7]),
  };
};

export const useConsentStatusByFile = (item?: File, share?: any) => {
  const consentManagementEnabled = useSelector(
    state => state.app.customerFunctions?.F_CONSENT_MANAGEMENT
  );

  if (!item || !consentManagementEnabled) return undefined;

  const consentStatusString = item?.propertiesById['nibo:consent-status'];

  let consentStatus: ConsentStatus | undefined = Object.values(
    ConsentStatus
  ).includes(consentStatusString as ConsentStatus)
    ? (consentStatusString as ConsentStatus)
    : undefined;

  if (share != null && share.type === 'c') {
    if (item.consents && item.consents.length > 0) {
      consentStatus = item.consents[0].status;
    }
  }

  return consentStatus;
};

export const getLocalizedConsentStatus = (status?: ConsentStatus) => {
  if (!status) return '';
  const statusTexts = {
    [ConsentStatus.ACCEPTED]: t`Consent accepted`,
    [ConsentStatus.DECLINED]: t`Consent declined`,
    [ConsentStatus.EXPIRED]: t`Consent expired`,
    [ConsentStatus.NOT_RESPONDED]: t`Pending response`,
    [ConsentStatus.NOT_TAGGED]: t`Pending tagging`,
    [ConsentStatus.NOT_REQUESTED]: t`Ready for request`,
    [ConsentStatus.CLOSED]: t`Request closed`,
    [ConsentStatus.MISSING_TERMS]: t`Missing terms`,
  };

  return statusTexts[status];
};

export const getConsentStatusColor = (status?: ConsentStatus, theme?: any) => {
  if (!status || !theme) return '';

  const statusColors = {
    [ConsentStatus.ACCEPTED]: theme.palette.commenting.status.success,
    [ConsentStatus.DECLINED]: theme.palette.commenting.status.warning,
    [ConsentStatus.EXPIRED]: theme.palette.commenting.status.warning,
    [ConsentStatus.NOT_RESPONDED]: theme.palette.commenting.status.waiting,
    [ConsentStatus.NOT_TAGGED]: theme.palette.commenting.status.waiting,
    [ConsentStatus.NOT_REQUESTED]: theme.palette.commenting.status.waiting,
    [ConsentStatus.CLOSED]: theme.palette.commenting.status.warning,
    [ConsentStatus.MISSING_TERMS]: theme.palette.commenting.status.waiting,
  };

  return statusColors[status];
};
